<template>
  <div>
    <dialog-edit :value="value" @input="$emit('input')">
      <template v-slot:title
        >{{ data.name }}
        <small v-if="id"> ({{ id }})</small>
      </template>

      <div style="text-align: initial;" v-html="data.content"></div>

      <template v-slot:actions>
        <v-spacer></v-spacer>
        <v-btn @click="$emit('input')">Закрыть</v-btn>
      </template>
    </dialog-edit>
  </div>
</template>

<script>
export default {
  props: {
    value: Boolean,
    id: Number,
  },
  data() {
    return {
      api: "/kazan/information",
      data: {},
    };
  },
  computed: {},
  watch: {
    value() {
      if (this.value) {
        this.updateData(this.id);
      }
    },
  },
  methods: {
    async updateData() {
      const r = await this.$axios.get(this.api + "/" + this.id);
      this.data = r.data.data;
    },
  },
};
</script>